import * as types from './Types'

const initialState = {
    businessActivity: [],
    userData: [],
    payoutMothYear: [],
    loading: true
}

export const BusinessReportReducers = (state = initialState, action) => {
    const { type, payload } = action
    switch (type) {
        case types.GET_STANDERD_BUSINESS_ACTIVITY:
            return {
                ...state,
                businessActivity: payload,
                loading: false
            }
        case types.GET_USERS_DATA:
            return {
                ...state,
                userData: payload,
                loading: false
            }
        case types.GET_REPORT_MONTH_YEAR:
            return {
                ...state,
                payoutMothYear: payload,
                loading: false
            }
        default:
            return state
    }
}

export default BusinessReportReducers