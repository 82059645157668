import * as types from './Types'

const initialState = {
    GCVPCVBodyType: [],
    GCVPCVBodyList: [],
    GCVPCVMake: [],
    GCVPCVModel: [],
    GCVPCVFuel: [],
    GCVPCVVeriant: [],
    GCVPCVRto: [],
    ActiveInsurer: [],
    PcvJourney: {},
    PCVLeadInqId: {},
    QuotesRequest: {},
    AllKycResponse: {},
    GetQuotesResponse: [],
    notQuotesData: [],
    SelectedInsurerDetails: [],
    ProposalInfo: {},
    proposalResponse: {},
    CityBasedOn: {},
    Riders: {},
    StateNames: {},
    documentUpload: {},
    isKycDone: [],
    getGodigitKyc: [],
    loading: true
}

export const GcvPcvReducers = (state = initialState, action) => {
    const { type, payload } = action
    switch (type) {
        case types.GET_PCVGV_BODY_TYPE:
            return {
                ...state,
                GCVPCVBodyType: payload,
                loading: false
            }
        case types.GET_BODY_TYPE_LIST:
            return {
                ...state,
                GCVPCVBodyList: payload,
                loading: false
            }
        case types.GET_ALL_GCVPCV_MAKE:
            return {
                ...state,
                GCVPCVMake: payload,
                loading: false
            }
        case types.GET_ALL_GCVPCV_MODEL:
            return {
                ...state,
                GCVPCVModel: payload,
                loading: false
            }
        case types.GET_ALL_GCVPCV_FUEL:
            return {
                ...state,
                GCVPCVFuel: payload,
                loading: false
            }
        case types.GET_ALL_GCVPCV_VARINAT:
            return {
                ...state,
                GCVPCVVeriant: payload,
                loading: false
            }
        case types.GET_ALL_GCVPCV_RTO:
            return {
                ...state,
                GCVPCVRto: payload,
                loading: false
            }
        case types.STORED_PCV_JOURNEY:
            return {
                ...state,
                PcvJourney: payload,
                loading: false
            }
        case types.GET_GCV_PCV_LEADS:
            return {
                ...state,
                PCVLeadInqId: payload,
                loading: false
            }
        case types.GET_ACTIVE_INURER:
            return {
                ...state,
                ActiveInsurer: payload,
                loading: false
            }
        case types.STORE_QUOTES_REQUEST:
            return {
                ...state,
                QuotesRequest: payload,
                loading: false
            }
        case types.STORE_RIDERS:
            return {
                ...state,
                Riders: payload,
                loading: false
            }
        case types.SELECTED_INSURARER_DETAILS:
            return {
                ...state,
                SelectedInsurerDetails: payload,
                loading: false
            }
        case types.GET_ALL_STATE:
            return {
                ...state,
                StateNames: payload,
                loading: false
            }
        case types.GET_CITY_BASED_STATE:
            return {
                ...state,
                CityBasedOn: payload,
                loading: false
            }

        case types.DOCUMENT_UPLOAD: return {
            ...state,
            documentUpload: payload,
            loading: false
        }
        case types.GET_QUOTES_RESPONSE:
            const uniqueQuotesData = new Set([...state.GetQuotesResponse.map(JSON.stringify), ...action.payload.map(JSON.stringify)])
            const updatedQuotesData = Array.from(uniqueQuotesData, JSON.parse)
            return {
                ...state,
                GetQuotesResponse: updatedQuotesData,
                loading: false
            }

        case types.NOT_GET_QUOTES:
            const updatedQuotesMap = new Map()
            state.notQuotesData.forEach(item => updatedQuotesMap.set(item.insurerLogo, item))
            action.payload.forEach(item => updatedQuotesMap.set(item.insurerLogo, item))
            const noupdatedQuotesData = Array.from(updatedQuotesMap.values())
            return {
                ...state,
                notQuotesData: noupdatedQuotesData,
                loading: false
            }
        case types.ALL_KYC_RESPONSE:
            return {
                ...state,
                AllKycResponse: payload,
                loading: false
            }
        case types.STORE_PROPOSAL_INFO:
            return {
                ...state,
                ProposalInfo: payload,
                loading: false
            }

        case types.GET_GODIGIT_KYC_CONTROLLER:
            return {
                ...state,
                isKycDone: payload,
                loading: false
            }

        case types.GET_KYC_DETAIL:
            return {
                ...state,
                getGodigitKyc: payload,
                loading: false
            }
        case types.GET_PROPOSAL_RESPONSE:
            return {
                ...state,
                proposalResponse: payload,
                loading: false
            }
        case types.RESET_PERPOSAL_INFO:
            return {
                ...state,
                AllKycResponse: initialState.AllKycResponse,
                ProposalInfo: initialState.ProposalInfo,
                proposalResponse: initialState.proposalResponse
            }
        case types.RESET_GET_QUOTES_RESPONSE:
            return {
                ...state,
                GetQuotesResponse: initialState.GetQuotesResponse,
                notQuotesData: initialState.notQuotesData

            }
        case types.RESET_PCV_JOURNEY:
            return {
                ...state,
                GCVPCVBodyType: initialState.GCVPCVBodyType,
                GCVPCVBodyList: initialState.GCVPCVBodyList,
                GCVPCVMake: initialState.GCVPCVMake,
                GCVPCVModel: initialState.GCVPCVModel,
                GCVPCVFuel: initialState.GCVPCVFuel,
                GCVPCVVeriant: initialState.GCVPCVVeriant,
                PcvJourney: initialState.PcvJourney,
                GCVPCVRto: initialState.GCVPCVRto,
                QuotesRequest: initialState.QuotesRequest
            }
        default:
            return state
    }
}

export default GcvPcvReducers