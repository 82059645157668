
// ********************************************************************** Country

export const GET_ALL_MAKES = "GET_ALL_MAKES"
export const GET_ALL_MODEL = "GET_ALL_MODEL"
export const GET_ALL_FUEL = "GET_ALL_FUEL"
export const GET_ALL_VARIANT = "GET_ALL_VARIANT"
export const GET_ALL_RTO = "GET_ALL_RTO"
export const GET_ALL_STATE = "GET_ALL_STATE"
export const GET_ALL_CITY = "GET_ALL_CITY"
export const GET_ALL_RELATION = "GET_ALL_RELATION"
export const GET_ALL_OCCUPATION = "GET_ALL_OCCUPATION"
export const GET_ALL_FINANCER = "GET_ALL_FINANCER"
export const GET_INSURER = "GET_INSURER"
export const GET_PREVIOUS_INSURER = "GET_PREVIOUS_INSURER"
export const RESET_FORM_DATA = "RESET_FORM_DATA"
export const RESET_JOURNEY_FORM_DATA = "RESET_JOURNEY_FORM_DATA"
export const GET_CASHLESS_GARAGES = "GET_CASHLESS_GARAGES"
export const SELECTED_PRODUCT_CAR_ADDED = "SELECTED_PRODUCT_CAR_ADDED"
export const HDFC_KYC_RESP = "HDFC_KYC_RESP"


// ******************************************************************** Quotes Types
export const GET_QUOTES = "GET_QUOTES"
export const GET_QUOTES_BASED_LEAD = "GET_QUOTES_BASED_LEAD"
export const ADD_MAKE = "ADD_MAKE"
export const ADD_MODEL = "ADD_MODEL"
export const ADD_FUEL = "ADD_FUEL"
export const ADD_VARIANT = "ADD_VARIANT"
export const ADD_RTO = "ADD_RTO"
export const ADD_YEAR = "ADD_YEAR"
export const ADD_ALLQUOTES = "ADD_ALLQUOTES"
export const ADD_RIDER = "ADD_RIDER"
export const GET_ALL_ACTIVE_INSURER = "GET_ALL_ACTIVE_INSURER"
export const GET_QUOTES_BASED_QUOTES_ID = "GET_QUOTES_BASED_QUOTES_ID"

// ******************************************************************** Proposal 
export const OWNWE_DETAIL = "OWNWE_DETAIL"
export const PERSONAL_DETAILS = "PERSONAL_DETAILS"
export const VEHICLE_INFO = "VEHICLE_INFO"
export const ADDRESS_DETAIL = "ADDRESS_DETAIL"
export const VEHICLE_ADDRESS_DETAIL = "VEHICLE_ADDRESS_DETAIL"
export const GET_PROPOSAL_PROPOSALID = "GET_PROPOSAL_PROPOSALID"

export const GET_PROPOSAL = "GET_PROPOSAL"
export const GET_SINGLE_PROPOSAL = "GET_SINGLE_PROPOSAL"

// ************ KYC
export const GET_KYC_DETAIL = "GET_KYC_DETAIL"
export const GET_GODIGIT_KYC_CONTROLLER = "GET_GODIGIT_KYC_CONTROLLER"
export const EMPTY_PROPOSAL_RES = "EMPTY_PROPOSAL_RES"
export const DOCUMENT_UPLOAD = "DOCUMENT_UPLOAD"
export const KYC_RESPONSE_DATA = "KYC_RESPONSE_DATA"
export const STORE_HDFC_STATE_DATA = "STORE_HDFC_STATE_DATA"